<div class="col-xl-12" style="overflow: hidden;">
	<div mat-dialog-content>
		<div class="header">
			<div class="title">
				<span>{{data['firstName']}} {{data['lastName']}}</span>
				<span>{{data['email']}} </span>
				<span>{{data['phoneNumber']}}</span> 
			</div>
			
		</div>  
		<div class="fieldItem" [style.height]="iframeHeight">
			
			<span *ngIf="loading">Loading...</span>
			<iframe  id="iframeMain" #iframeMain width="100%" frameBorder="0" [src]="iframe_html" (load)="onload($event)" [height]="iframeHeight"></iframe>
		</div>
	</div>
	<div mat-dialog-actions style='margin-left:24px;'>
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">Close</button>
	</div>
	<br/>
</div>
