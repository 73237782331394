import { environment } from '../environments/environment';
export class MenuConfig {
	public defaults: any = {
		aside: {
			self: {},
			items: [
				{
					title: 'Home',
					root: true,
					icon: 'dashboard',
					page: '/home',
					bullet: 'dot',
					permission: [],

				},
				{
					title: 'Patients',
					root: true,
					icon: 'group',
					page: '/patients',
					bullet: 'dot',
					permission: [],

				},
				{
					title: 'Scheduling',
					root: true,
					icon: 'calendar_today',
					page: '/scheduling',
					bullet: 'dot',
					permission: [],

				},
				// {
				// 	title: 'Notes',
				// 	root: true,
				// 	icon: 'notes',
				// 	page: '/notes',
				// 	bullet: 'dot',
				// 	permission: [],

				// },
				{
					title: 'Video Tutorials',
					root: true,
					icon: 'videocam',
					page: '/videoTutorials',
					bullet: 'dot',
					permission: [],

				}
			]
		},
	};

	public get configs(): any {


		return this.defaults;
	}
}
