<form #dataForm="ngForm" (ngSubmit)="onSubmit()">
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      <mat-form-field>
        <input matInput type="date" name="mydate" [required]="!metadata['startdate'].nullable"
          [(ngModel)]="dateStartValue" ng-model="'mydate'" placeholder="Date"
          (change)="setDateStartAttribute($event.target.value)" [errorStateMatcher]="esMatcher">
        <mat-error *ngIf="!metadata['startdate'].nullable && (dateStartValue === undefined || dateStartValue === '')">
          {{ metadata['startdate'].displayName | translate}} Date is required.
        </mat-error>
      </mat-form-field>
    </div>
    <div fxFlex fxLayoutAlign="start center">
      <mat-slide-toggle [checked]="allDay" color="primary" style="margin-right:5px;"
        (change)="setAllDayAttribute(!allDay)" [disabled]="metadata['allday'].disabled">
        {{metadata['allday'].displayName | translate}}
      </mat-slide-toggle>
      <!-- <mat-checkbox class="example-margin" name="allDay" [(ngModel)]="allDay"
        (change)="setAllDayAttribute( $event.checked)" title="{{metadata['allday'].info | translate}}"
        [disabled]="metadata['allday'].disabled">
        {{metadata['allday'].displayName}}
      </mat-checkbox> -->
    </div>
  </div>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      <div class="timeMenu">
        <mat-form-field>
          <input matInput type="time" name="myStartTime" [required]="!metadata['startdate'].nullable"
            [(ngModel)]="timeStartValue" ng-model="'myStartTime'" matInput [matAutocomplete]="autoStart"
            (change)="setTimeStartAttribute($event.target.value, 'change')" [errorStateMatcher]="esMatcher">
          <button matSuffix mat-icon-button>
            <mat-icon>expand_more</mat-icon>
          </button>
          <mat-autocomplete #autoStart="matAutocomplete" (opened)="onStartOpened()"
            (optionSelected)="setTimeStartAttribute($event.option.value, 'optionChange')">
            <mat-option #matOptionStart *ngFor="let itm of timeArray" [value]="itm.value"
              [selected]="timeStartValue== itm.value">
              {{itm.display}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="!metadata['startdate'].nullable && (timeStartValue === undefined || timeStartValue === '')">
            {{metadata['startdate'].displayName | translate}} Time is required.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div fxFlex>
      <div class="timeMenu">
        <mat-form-field>
          <input matInput type="time" name="myEndTime" [required]="!metadata['enddate'].nullable"
            [(ngModel)]="timeEndValue" ng-model="'myEndTime'" matInput [matAutocomplete]="autoEnd"
            (change)="setTimeEndAttribute($event.target.value, 'change')" [errorStateMatcher]="esMatcher">
          <button matSuffix mat-icon-button>
            <mat-icon>expand_more</mat-icon>
          </button>
          <mat-autocomplete #autoEnd="matAutocomplete" (opened)="onEndOpened()"
            (optionSelected)="setTimeEndAttribute($event.option.value, 'optionChange')">
            <mat-option #matOptionEnd *ngFor="let itm of timeArray" [value]="itm.value">
              {{itm.display}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="!metadata['enddate'].nullable && (timeEndValue === undefined || timeEndValue === '')">
            {{metadata['enddate'].displayName | translate}} Time is required.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>