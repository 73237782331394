// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverMainUrl: 'https://patient-dashboard.interactivelife.me/',
  serverUrl: 'https://api.staging.ili.ms/v2/',
  // serverUrl: 'https://staging.ili.ms/api/',
  scheduleUrl: 'https://staging.api.stellar.online/api/',
  // TODO: Update this with your productID and project name
  productId: '25e5e63a-dfe0-42c3-baa4-eede5fdeec2a',
  projectName: 'patientdashboard',
  serverName: 'Patient Dashboard',
  serverTileUrl: 'https://staging.ili.ms/',
  subServerName: '',
  profileImageURL: '',
  orgType: 'patientdashboard',
  stripe_links: {
    monthly: 'https://buy.stripe.com/test_3csaFK6Vkdgxdd6144',
    yearly: 'https://buy.stripe.com/test_aEU6puenMgsJb4Y001'
  },
  customKeys: {
 
  },
  customKeysView: {

  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
