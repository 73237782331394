import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Dictionary } from './interface';
import { ContentType } from './enums';
import { StoreService } from './store.service';
import { Utils } from '../helpers/utils';
import { NgxPermissionsService, NgxRolesService } from '../modules/ngx-permissions';
import { urlSafeBase64Encoding } from '../helpers';

type responseCallback = (dataResponse: any | undefined, requestError: any | undefined) => any;
@Injectable()
export class RequestService {
  private authURL = environment.serverUrl;
  private scheduleURL = environment.scheduleUrl;
  private orgType = environment.orgType;
  private token: any = '';
  private userType: string = 'default';
  public orgId: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public appId: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public locId: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public physicianId: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public orgName: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public appName: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public locName: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public physicianName: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);



  public appStatusSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public authenticatedUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private cachedObj = {};
  public currentUserSubject: BehaviorSubject<any | undefined> = new BehaviorSubject<any | undefined>(undefined);
  public _currentUser: any | undefined = undefined;
  public pageOrganization: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  set currentUser(currentUser: any | undefined) {
    if (currentUser) {
      this._currentUser = currentUser;
      let userObject: any = currentUser;
      this.userType = currentUser.type || 'default';
      this.store.init('default_' + userObject._id);
      this.orgId.next(this.store.get('orgId'));
      // this.appId.next(this.store.get('appId'));
      // this.locId.next(this.store.get('locId'));

      this.setToken(userObject.token);
      this.currentUserSubject.next(userObject);
      // this.store.set('te', 'temp');
      // this.permissionsService.flushPermissions();
      // this.permissionsService.addPermission(userObject.role.uid);
    } else {
      this._currentUser = undefined;
      this.currentUserSubject.next(undefined);
      this.token = '';
      this.userType = 'default';
      this.store.init('default');
      // this.permissionsService.flushPermissions();
    }
  }
  get currentUser(): any | undefined {
    return this._currentUser;
  }
  public updateCurrentUser(newData: any) {
    let objectUser = Object.assign(this._currentUser, newData);
    this.currentUser = objectUser;
  }
  constructor(private utils: Utils, public store: StoreService, private router: Router, private http: HttpClient, private route: ActivatedRoute,
    private permissionsService: NgxPermissionsService) {
    this.store.init('Anonomous');
  }
  public loggedIn() {
    if (this.currentUser) {
      return true;
    } else {
      return false;
    }
  }
  public getUserType() {
    return this.userType;
  }
  public getUserId() {
    if (this.currentUser && this.currentUser.hasOwnProperty('_id')) {
      return this.currentUser['_id'];
    } else {
      return '';
    }
  }
  public setToken(token: any) {
    this.token = token;
  }

  public getToken() {
    return this.token;
  }
  public addLanguageToURL(url: string, lang?: string): string {
    // if (url) {
    //   let langEnd = lang;
    //   if (langEnd === undefined) {
    //       langEnd = 'en';
    //   }
    //   if (~url.indexOf('?')) {
    //     url += '&locale=' + langEnd;
    //   } else {
    //     url += '?locale=' + langEnd;
    //   }
    //   return url;
    // } else {
    //   return '';
    // }
    return url;
  }

  public sendMessage(userId: any, message: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'sms/'+ this.orgId.getValue() + "/" + userId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', message);
  }

  public getListContains(lst: any[], idKey: string = '_id'): any[] {
    let dataList = [];
    for (let itm of lst) {
      if (itm.hasOwnProperty(idKey)) {
        dataList.push(itm[idKey]);
      }
    }
    return dataList;
  }
  public checkListContains(lst: any[], val: string, idKey: string = '_id'): boolean {
    for (let itm of lst) {
      if (itm.hasOwnProperty(idKey) && itm[idKey] === val) {
        return true;
      }
    }
    return false;
  }

  public deleteProfileImage(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/' + id + '/deleteprofile';
    urlStr = this.addLanguageToURL(urlStr, lang);

    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public logout() {

    localStorage.removeItem('currentUser');
    this.appStatusSubject.next(undefined);
    this.currentUser = undefined;

    var host = window.location.host;

    var orgLoginPage = window.location.host.split('.')[0];

    if (host == "localhost:4200" || host == "patient-dashboard.interactivelife.me" || host == "staging.patient-dashboard.interactivelife.me" || host == "patient.ili.ms" || host == "patient-dashboard.ili.ms" || host=="flotrak.ai.patient.ili.ms") {
      this.router.navigate(['/login']);
    }
    else {

      var orgLoginPage = window.location.host.split('.')[0];

      this.router.navigate(['/login' + orgLoginPage]);

    }

  }
  

  public getMe(callback: (dataResponse: any | undefined,
    requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'me';
    console.log(this.authURL);
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          let userObject = jsonObj.results;
          callback(userObject, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, 'Data error from server ');
      }
    });
  }
  public requestLogin(username: string, password: string, callback: (dataResponse: any | undefined,
    requestError: any | undefined, returnIt: boolean) => void, lang?: string) {
    let encodedPassword = urlSafeBase64Encoding(password); // make it from backend
    let urlStr = this.authURL + 'user/login?email=' + encodeURIComponent(username) + '&password=' + encodedPassword;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        console.log(error);
        if (jsonObj && jsonObj.hasOwnProperty('return')) {
          callback(undefined, error, jsonObj.return);
        } else {
          callback(undefined, error, false);
        }
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          let userObject = jsonObj.results;
          console.log(userObject);
          this.setToken(jsonObj.token);
          userObject['token'] = jsonObj.token;
          callback(userObject, undefined, false);
        } else {
          if (jsonObj.hasOwnProperty('return')) {
            callback(undefined, jsonObj.message, jsonObj.return);
          } else {
            callback(undefined, jsonObj.message, false);
          }
        }
      } else {
        callback(undefined, 'Data error from server ', false);
      }
    });
  }


  public validateUser(callback: (dataResponse: any | undefined,
    requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'user/valid';
    callback(true, undefined);
    // this.jsonGetRequest(urlStr, (jsonObj, error) => {
    //     if (error !== undefined) {
    //         callback(undefined, error);
    //         return;
    //     }
    //     if (jsonObj) {
    //       if (jsonObj.status) {
    //         callback(true, undefined);
    //       }else {
    //         callback(undefined, jsonObj.message);
    //       }
    //     } else {
    //       callback(undefined, 'Data error from server ');
    //     }
    // });
  }
  private toBase64(stringToSign: string) {
    let base64 = btoa(stringToSign);
    return base64;
  }
  public getMetaData(type: string, feilds: any[], callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL;
    if (type.toLocaleLowerCase().includes('schedule/event')) {
      urlStr = this.scheduleURL;
    }
    urlStr = urlStr + type + '/metadata';

    if (feilds) {
      urlStr = urlStr + '&fields=' + feilds
    }

    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public ValidateAppointment(type: string, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    
       let urlStr = this.scheduleURL + type + '/validate';
      
      // urlStr = this.addLanguageToURL(urlStr, lang);
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, 'Server Error!');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            if (jsonObj.hasOwnProperty('type')) {
              this.appStatusSubject.next(jsonObj.type);
            }
            callback(undefined, jsonObj.message);
          }
        } else {
          callback(undefined, error);
        }
      }, 'POST', data);
    
  }
  public saveData(type: string, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    if (data.hasOwnProperty('_id') && data._id) {
      let urlStr = this.authURL;
      if (type.toLocaleLowerCase().includes('schedule/event')) {
        urlStr = this.scheduleURL;
      }

      urlStr = urlStr + type + '/' + data._id;
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, 'Server Error!');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            if (jsonObj.hasOwnProperty('type')) {
              this.appStatusSubject.next(jsonObj.type);
            }
            callback(undefined, jsonObj.message);
          }
        } else {
          callback(undefined, error);
        }
      }, 'POST', data);
    } else {
      let urlStr = this.authURL;
      if (type.toLocaleLowerCase().includes('schedule/event')) {
        urlStr = this.scheduleURL;
      }
      urlStr = urlStr + type;
      // urlStr = this.addLanguageToURL(urlStr, lang);
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, 'Server Error!');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            if (jsonObj.hasOwnProperty('type')) {
              this.appStatusSubject.next(jsonObj.type);
            }
            callback(undefined, jsonObj.message);
          }
        } else {
          callback(undefined, error);
        }
      }, 'POST', data);
    }
  }
  public cropImageByOrg(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'img/crop/' + this.orgId.getValue();
    // urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, 'Server Error!');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public getSingleData(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {

    let urlStr = this.authURL;

    if (type.toLocaleLowerCase().includes('schedule/event')) {
      urlStr = this.scheduleURL;
    }

    urlStr += type + '/' + id;

    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getMySingleData(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/' + id;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public deleteSingleData(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    
    let urlStr = this.authURL;
    
    if (type.toLocaleLowerCase().includes('schedule/event')) {
      urlStr = this.scheduleURL;
    }


     urlStr +=  type + '/' + id + '/delete';

    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST');
  }
  public deleteImageDataByOrg(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/delete/' + this.orgId.getValue();
    urlStr = this.addLanguageToURL(urlStr, lang);
    let objData = {
      // folder: "string",
      url: id
    };
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', objData);
  }
  public deleteBackgroundImageData(type: string, id: string, imageUrl: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'img/delete/' + id + '/tilebackground';
    let data = {
      type: type,
      imageUrl: imageUrl
    };
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public getDataListByGet(type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/list';
    // let urlStr = this.authURL + 'app/' + type;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getDataListByOrgByGet(type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/list/' + this.orgId.getValue();
    // let urlStr = this.authURL + 'app/' + type;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }

  public getDataByOrgByGet(type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/get/' + this.orgId.getValue();
    // let urlStr = this.authURL + 'app/' + type;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }

  public getStatistics(appID, locationID, physicianID: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {

    let urlStr = this.authURL + 'patient/statics/' + appID + '/' + locationID + '/' + physicianID;
    // let urlStr = this.authURL + 'app/' + type;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getDataList(type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, source: string = '', sourceTarget: string = '', lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL;
    if (type.toLocaleLowerCase().includes('schedule/event')) {
      urlStr = this.scheduleURL;
    }

    urlStr = urlStr + source + type + '/search' + sourceTarget;
    // let urlStr = this.authURL + source + type + '/search' + sourceTarget;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getDataListByListByOrg(type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, source: string = '', sourceTarget: string = '', lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL + source + type + '/list/' + this.orgId.getValue();
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getDataListByList(type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, source: string = '', sourceTarget: string = '', lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL + source + type + '/list';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getDataListSummary(type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');

    let urlStr = this.authURL;
    if (type.toLocaleLowerCase().includes('schedule/event')) {
      urlStr = this.scheduleURL;
    }
    urlStr = urlStr + type + '/search/summary';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (cached) {
            this.cachedObj[type] = jsonObj;
          }
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getPhysicianListSummaryByUser(type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {

    let cleanConf = this.buildSearchRequestSToAPI(conf, '');

    let urlStr = this.authURL + type + '/user/search/' + this.orgId.getValue() + "/" + this.getUserId();
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (cached) {
            this.cachedObj[type] = jsonObj;
          }
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  // downloadFile(data: Response) {
  //   const blob = new Blob([data], { type: 'text/csv' });
  //   const url= window.URL.createObjectURL(blob);
  //   window.open(url);
  // }
  public getThemes(orgId: string, id: string, createdOrg: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    var urlStr = this.authURL + 'tiletheme/list';
    var query = {};

    if (this.utils.isNullOrEmpty(createdOrg)) {
      if (!this.utils.isNullOrEmpty(id)) {
        query["_id"] = id;
      } else {
        query["organizationId"] = orgId;
      }
    } else {
      query["createdOrg"] = createdOrg;
    }
    urlStr = this.addLanguageToURL(urlStr, lang);
    let lgObj = 'form_data=' + encodeURI(JSON.stringify(query));
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', lgObj);
  };
  public getDataLByOrg(type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {
    if (cached) {
      if (this.cachedObj.hasOwnProperty(type + '/' + this.orgId.getValue())) {
        callback(this.cachedObj[type + '/' + this.orgId.getValue()], undefined);
        return;
      }
    }
    // let urlStr = this.authURL  + type;
    let urlStr = this.authURL + type + '/list/' + this.orgId.getValue();
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (cached) {
            this.cachedObj[type + '/' + this.orgId.getValue()] = jsonObj;
          }
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getDataLByOrgType(datatype: string, type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {
    if (cached) {
      if (this.cachedObj.hasOwnProperty(datatype + '/' + this.orgId.getValue() + '/' + type)) {
        callback(this.cachedObj[datatype + '/' + this.orgId.getValue() + '/' + type], undefined);
        return;
      }
    }
    let urlStr = this.authURL + datatype + '/list/' + this.orgId.getValue() + '/' + type;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (cached) {
            this.cachedObj[datatype + '/' + this.orgId.getValue() + '/' + type] = jsonObj;
          }
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getDataL(type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {
    if (cached) {
      if (this.cachedObj.hasOwnProperty(type)) {
        callback(this.cachedObj[type], undefined);
        return;
      }
    }
    let urlStr = this.authURL

    if (type == "patients") {
      urlStr += 'physician/patients/' + this.orgId.getValue() + "/" + this.appId.getValue() + "/" + this.locId.getValue() + "/" + this.physicianId.getValue();
    }
    else {
      urlStr += type + '/list';

    }
    // let urlStr = this.authURL  + type;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (cached) {
            this.cachedObj[type] = jsonObj;
          }
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getUsersList(conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL + 'user/search';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getMyUsersList(conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL + 'my/user/search/summary';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getUser(userId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'user/' + userId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public registeruser(user: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'user/register';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', user);
  }
  public editUser(userId: any, user: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'user/' + userId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', user);
  }
  public editUserPassword(password: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'changepassword/';
    let user = { password: password }
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', user);
  }
  public resetUserPassword(userId: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'resetpassword/' + userId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public requestPassword(useremail: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'requestpassword/' + useremail;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public editSelfUser(userId: any, user: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'userself/' + userId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', user);
  }
  private buildSearchRequestSToAPI(conf: any, token: string = '', addCustomData: boolean = true): {} {
    let searchRequestGeneric: any = {
      count: conf.perpage || 10
    };
    if (searchRequestGeneric.count === -1) {
      delete searchRequestGeneric.count;
    }
    if (conf.orderBy && conf.orderDir) {
      searchRequestGeneric['order'] = [];
      searchRequestGeneric['order'].push({ field: conf.orderBy, order: conf.orderDir });
    }
    let fieldList: string[] = [];
    if (conf.hasOwnProperty('fieldKeys')) {
      fieldList = conf['fieldKeys'];
    }
    if (fieldList.length > 0) {
      searchRequestGeneric['fields'] = fieldList;
    }
    if (conf.hasOwnProperty('term') && conf['term'] !== undefined) {
      searchRequestGeneric['term'] = conf['term'] || '';
    }
    if (conf.hasOwnProperty('termfields') && conf['termfields'] !== undefined) {
      searchRequestGeneric['termfields'] = conf['termfields'] || '';
    }
    let filterList = {};
    if (conf.customData && addCustomData) {
      if (Object.keys(conf.customData).length > 0) {
        for (let field of Object.keys(conf.customData)) {
          if (field)
            filterList[field] = { op: 'eq', value: conf.customData[field] };
        }
      }
    }
    if (conf.filterFieldKey) {
      for (let field of conf.filterFieldKey) {
        if (field) {
          filterList[field.field] = { op: field.op, value: field.search };
          if (field.type && field.type === 'number') {
            filterList[field.field].value = Number(filterList[field.field].value);
          }
        }
      }
    }
    if (Object.keys(filterList).length > 0) {
      searchRequestGeneric['filter'] = filterList;
    }
    if (conf.hasOwnProperty('filter')) {
      searchRequestGeneric['filter'] = conf.filter;
    }
    if (conf.hasOwnProperty('page')) {
      searchRequestGeneric['page'] = conf.page;
    }
    if (token !== '') {
      searchRequestGeneric['paginationToken'] = token;
    }
    if (conf.hasOwnProperty('include') && conf['include'] !== undefined) {
      searchRequestGeneric['include'] = conf['include'] || [];
    }
    if (conf.hasOwnProperty('exclude') && conf['exclude'] !== undefined) {
      searchRequestGeneric['exclude'] = conf['exclude'] || [];
    }
    return searchRequestGeneric;
  }
  private urlEncode(str: string): string {
    return encodeURI(str);
  }

  public getUserNotesList(callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + "usernotes/list";
    // let urlStr = this.authURL + 'app/' + type;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getHsrAttentionList(type: string, orgId: any, appId: any, locationId: any, physicianId: any, count: number, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/hsrattention/' + orgId + '/' + appId + '/' + locationId + '/' + physicianId + '/' + count;
    // let urlStr = this.authURL + 'app/' + type;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getprocedureFormList(orgId: any, appId: any, locationId: any, procedureMappingId: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL  + 'procedure/formstatus/' + orgId + '/' + appId + '/' + locationId + '/' + procedureMappingId ;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getFsAttentionList(type: string, orgId: any, appId: any, locationId: any, physicianId: any, count: number, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/fsattention/' + orgId + '/' + appId + '/' + locationId + '/' + physicianId + '/' + count;
    // let urlStr = this.authURL + 'app/' + type;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public deleteUserNotes(data: { _id: any, userId: any }, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    
    
    const apiUrl = this.authURL + "usernotes/" + data._id + "/delete";
    this.jsonRequest(apiUrl, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }

  public saveUserNotes(data: { note: any }, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    const apiUrl = this.authURL + "usernotes";
    this.jsonRequest(apiUrl, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  private jsonRequestSimple(urlString: string, callback: (json?: any, error?: any) => void, params: Dictionary, timeout: number = 60.0) {
    let body;
    if (params) {
      body = params;
    } else {
      // we need to recheck this
      console.log('Parameters sent to jsonRequestSimple are not serializable into JSON');
    }
    this.jsonRequest(urlString, (json, error) => {
      callback(json, error);
    }, 'POST', body, ContentType.JSON, timeout);
  }
  private jsonGetRequest(urlString: string, callback: (json?: any, error?: any) => void, params?: Dictionary) {
    if (urlString) {
      let urlComps = urlString;
      if (params) {
        for (let urlItem of Object.keys(params)) {
          urlComps += '&' + urlItem + '=' + params[urlItem];
        }
      }
      this.jsonRequest(urlComps, callback, 'GET');
    } else {
      return;
    }
  }
  private jsonRequest(urlString: string,
    callback: (json: any, error: any) => void,
    method: string = 'POST',
    postBody: any = undefined,
    contentType: string = ContentType.JSON,
    timeout: number = 10.0,
    retry: boolean = false,
    retryFactor: number = 1.5,
    maxTimeout: number = 60.0) {
    if (urlString) {
      let url: string = urlString || '';
      // this.logger.log(url, method, postBody, contentType, timeout, retry, retryFactor, maxTimeout);
      // console.log(url, method, postBody, contentType, timeout, retry, retryFactor, maxTimeout);
      let headers = {
        'Accept': 'application/json',
      };

      if (urlString.includes('schedule/event')) {
        headers['project'] = environment.projectName;
        //headers['productid'] = environment.productId;
        headers['Authorization'] = this.token;

      }
      if (contentType)
        headers['Content-Type'] = contentType;
      if (this.token && urlString.startsWith(this.authURL)) {
        headers['Authorization'] = this.token;
      }
      const httpHeaders = new HttpHeaders(headers);
      let httpOptions: any = {
        responseType: 'json',
        headers: httpHeaders,
        method: method
      }

      let bodyString = postBody;
      if (method === 'POST') {
        bodyString = JSON.stringify(postBody);
        httpOptions['body'] = bodyString;
      }
      return this.http.request(method, url, httpOptions)
        .subscribe(
          (data) => {
            callback(data, undefined);
          },
          (err) => {
            if (err) {
              if (err.status >= 404) {
                // window.location.reload();
                callback(undefined, 'Refresh page');
              } else if (err.status >= 400) {
                if (this.currentUser) {
                  try {
                    let jsonErr = err.json();
                    if (jsonErr.hasOwnProperty('type') && jsonErr.type === 'login') {
                      this.appStatusSubject.next(jsonErr.type);
                      // this.logout();
                    } else {
                      callback(undefined, ' Connectivity issue.');
                    }
                  } catch (e1) {
                    try {
                      if (err.hasOwnProperty('error')) {
                        let jsonErr = err.error;
                        if (jsonErr.hasOwnProperty('type') && jsonErr.type === 'login') {
                          this.appStatusSubject.next(jsonErr.type);
                          // this.logout();
                        } else {
                          callback(undefined, ' Connectivity issue.');
                        }
                      } else {
                        callback(undefined, ' Connectivity issue.');
                      }
                    } catch (e2) {
                      callback(undefined, ' Connectivity issue.');
                    }
                  }
                }
              } else {
                callback(undefined, ' Connectivity issue.');
              }
            }
          });

    } else {
      // this.logger.log('Failed to create URL');
      console.log('Failed to create URL');
    }
  }
  public updateUserData(key, value) {
    let userObject: any = this.currentUserSubject.getValue();
    // let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    userObject[key] = value;
    localStorage.setItem('currentUser', JSON.stringify(userObject));
    this.currentUserSubject.next(userObject);
  }
  public onUploadCharacterImage(browsed_file: any, characterId: string): Observable<{}> {
    let headers = {
      'Authorization': this.token,
      'Accept': 'application/json',
      'vertical': this.orgType
    };
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    // headers.append('Authorization', 'bearer ' + this.token);
    let url = this.authURL + 'character/image/upload/' + characterId;
    let formData = new FormData();
    formData.append('upfile', browsed_file.originalFile);
    formData.append('name', browsed_file.text);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }

  public onUploadFilesByPath(path: string, browsed_file: any): Observable<{}> {
    let headers = {
      'Authorization': this.token,
      'Accept': 'application/json',
      'vertical': this.orgType
    };
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let fileType = { 'type': "profilephoto" };
    let url = this.authURL + path + this.getUserId();
    let formData = new FormData();
    // formData.append('file', browsed_file.originalFile, browsed_file.originalFile.name);
    formData.append('upfile', browsed_file.originalFile);
    formData.append('name', browsed_file.text);

    // let body = {formData: formData, jsonPayload: fileType, memberId: this.getUserId() };

    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }

  public onUploadUserImage(browsed_file: any, userId: string): Observable<{}> {

    let headers = {
      'Authorization': this.token,
      'Accept': 'application/json'
    };
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    // headers.append('Authorization', 'bearer ' + this.token);
    let url = this.authURL + 'resource/user/image/upload/' + userId;
    let formData = new FormData();
    formData.append('upfile', browsed_file.originalFile);
    formData.append('name', browsed_file.text);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }
  public onUploadUserTranscript(browsed_file: any, userId: string, transcript: any): Observable<{}> {
    let headers = {
      'Authorization': this.token,
      'Accept': 'application/json'
    };
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = this.authURL + 'transcript/upload/' + userId;
    let formData = new FormData();
    formData.append('upfile', browsed_file.originalFile);
    formData.append('name', browsed_file.text);
    formData.append('year', transcript.year);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }
  public passwordReset(body, callback: responseCallback) {
    let urlStr = this.authURL + 'user/hipaa/changepassword/' + this.getUserId();

    let encodedOldPassword = urlSafeBase64Encoding(body.oldpassword);
    let encodedNewPassword = urlSafeBase64Encoding(body.newpassword);
    let userData = { oldPassword: encodedOldPassword, newPassword: encodedNewPassword };

    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, 'Server Error');
      }

      if (jsonObj) {
        callback(jsonObj, undefined);
      }

      else {
        callback(jsonObj, error);
      }
    }, 'POST', userData)
  }
  public onUploadFiles(browsed_file: any, folder: string = '', type: string = '', isEncoded: string = 'false'): Observable<{}> {
    let headers = {
      'Authorization': this.token,
      'Accept': 'application/json'
    };
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = this.authURL + 'img/upload/' + this.orgId;
    let formData = new FormData();
    // formData.append('file', browsed_file.originalFile, browsed_file.originalFile.name);
    formData.append('upfile', browsed_file.originalFile);
    formData.append('folder', folder);
    formData.append('type', type);
    formData.append('isEncoded', isEncoded);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }
  public onUploadBackgroundFiles(tileId: string, type: string, browsed_file: any): Observable<{}> {
    let headers = {
      'Authorization': this.token,
      'Accept': 'application/json'
    };
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = this.authURL + 'img/upload/' + tileId + '/tilebackground';
    let formData = new FormData();
    // formData.append('file', browsed_file.originalFile, browsed_file.originalFile.name);
    formData.append('upfile', browsed_file.originalFile);
    formData.append('tileId', tileId);
    formData.append('type', type);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }
  public isUserRoleAdmin(): boolean {
    let user = this.currentUser;
    // if (user) {
    //   if (user['resources'].find(role => role['_id'] == environment.customKeys.roleAdmin))
    //     return true;
    // }
    return true;
  }
  public isUserRoleSuperAdmin(): boolean {
    let user = this.currentUser;
    // if (user && user.isSuperAdmin) {
    //   if (user['resources'].find(role => role['_id'] === environment.customKeys.roleAdmin && role['organizationId'] === this.orgId))
    // return true;
    // }
    return false;
  }
}