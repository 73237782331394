import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { RequestService, StoreService } from '../../../shared/services';
import { CustomSelectAutocompleteComponent } from '../../../shared/components/custom-select-autocomplete/custom-select-autocomplete.component';
import { ModalUserViewDialogComponent } from '../custom-user-view-dialog/custom-user-view-dialog.component';
import { ModalFieldCropDialogComponent } from '../../../shared/components/custom-field-crop-dialog/custom-field-crop-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';


@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;
  public title = environment.serverName;
  public subTitle = environment.subServerName;
  public pushRightClass: string;
  public selectedOrganization: string = '';
  public selectedApp: string = ' ';
  public selectedLocation: string = ' ';
  public selectedPhysician: string = ' ';
  public pictureLink: string = 'assets/images/profile.png';
  public userType: string = 'default';
  public dataType: string = 'organization';
  public dataTypeDisplay: string = 'Organization';
  public selectedDatafilters: any = undefined;
  public ThemeColor: string = "primary";
  public LogoImage: string = "assets/images/logo.png";
  // public consultationImage: string = "assets/images/consultation_mybackPathways.png";
  public orgName: string = '';
  public notificationsLoaded: boolean = false;
  public notificationsList: any[] = [];
  public hasNew: boolean = false;
  date: Date;
  orgsList: any[] = [];
  public selectedAppDatafilters: any = undefined;
  public selectedLocationDatafilters: any = undefined;
  public selectedPhysicianDatafilters: any = undefined;

  public reloadPage: boolean = false;




  @ViewChild('customselectautocomplete') customselectautocomplete: CustomSelectAutocompleteComponent;
  @ViewChild('customappselectautocomplete') customappselectautocomplete: CustomSelectAutocompleteComponent;
  @ViewChild('customlocationselectautocomplete') customlocationselectautocomplete: CustomSelectAutocompleteComponent;
  constructor(private requestService: RequestService,
    private storeService: StoreService, public router: Router, private translate: TranslateService,
    public dialog: MatDialog) {
    // this.router.events.subscribe(val => {
    //     if (val instanceof NavigationEnd && window.innerWidth <= 992 && this.isToggled()) {
    //         this.toggleSidebar();
    //     }
    // });
    this.userType = this.requestService.getUserType();
  }

  ngOnInit() {

    this.date = new Date();
    this.date.setDate(this.date.getDate());

    this.pushRightClass = 'push-right';
    this.setThemeColors();

    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {

          this.selectedUser = data;

          if (this.selectedUser.profile) {
            this.pictureLink = this.selectedUser.profile;
          }

          this.orgsList = this.requestService.getListContains(this.selectedUser['organizationId'], '_id');

          let filter = { '$and': [] };
          filter['$and'].push({ '_id': { '$in': this.orgsList } });

          this.selectedDatafilters = filter;
          if (this.selectedUser && this.selectedUser.hasOwnProperty('organizationId') && this.selectedUser['organizationId'].length > 0) {
            var host = window.location.host;
            var loginPage = host.split(".")[0];
            const flotrakRecord = this.selectedUser['organizationId'].find(({ _id }) => _id === '5b106165ecedfa82a7dd46ed');

            
            let selectedOrgRecord = undefined;

            if (loginPage == "flotrak") {
              selectedOrgRecord = this.selectedUser['organizationId'].find(({ _id }) => _id === '5b106165ecedfa82a7dd46ed');

            }
            else {
             selectedOrgRecord = this.selectedUser['organizationId'].find(({ _id }) => _id === this.requestService.orgId.getValue());

            }

              if (this.storeService.get('appId') !== undefined) {

                this.setSelectedOrganization(selectedOrgRecord, false, true);

                let selectedAppRecord = { _id: this.storeService.get('appId'), text: "" }

                if (this.storeService.get('locId')) {

                  let filter = { '$and': [] };

                  filter['$and'].push({ 'organizationId': { '$eq': this.requestService.orgId.getValue() } });

                  this.selectedAppDatafilters = filter;

                  this.setSelectedApp(selectedAppRecord, false, true);


                  let selectedLocRecord = { _id: this.storeService.get('locId'), text: "" }

                  if (this.storeService.get('physicianId')) {

                    filter = { '$and': [] };

                    filter['$and'].push({ 'appId': { '$eq': this.storeService.get('appId') } });

                    this.selectedLocationDatafilters = filter;

                    this.setSelectedLocation(selectedLocRecord, false, true);

                    let selectedPhysicianRecord = { _id: this.storeService.get('physicianId'), text: "" }

                    filter = { '$and': [] };

                    filter['$and'].push({ 'locationId': { '$eq': this.storeService.get('locId') } });

                    this.selectedPhysicianDatafilters = filter;
                    
                    this.setSelectedPhysician(selectedPhysicianRecord, false);

                  } else {
                    this.setSelectedLocation(selectedLocRecord, false, false);
                  }


                }
                else {
                  this.setSelectedApp(selectedAppRecord, false, false);
                }
              }
              else {
                this.setSelectedOrganization(this.selectedUser['organizationId'][0], false);

              }

            }

          }
      // }

      })
    );
    this.subscriptions.push(
      this.requestService.orgId.subscribe((data) => {
        if (data) {
          this.selectedOrganization = data;
        }
      })
    );
  }

  goToConsultationLink() {

    // window.open("https://mybackpathways.com/tutorials", "_blank");

  }

  goToLink() {
    var host = window.location.host;
    var loginPage = host.split(".")[0];
    if (loginPage == "flotrak") {

      window.open("https://mybackpathways.com/tutorials", "_blank");

    }

  }

  setThemeColors() {

    var host = window.location.host;

    if (host == "localhost:4200" || host == "patient-dashboard.interactivelife.me" || host == "staging.patient-dashboard.interactivelife.me" || host == "patient.ili.ms" || host == "patient-dashboard.ili.ms" || host == "flotrak.ai.patient.ili.ms") {
      this.ThemeColor = "primary";
      this.LogoImage = "assets/images/logo_flotrak.png";

      // (document.querySelector('#topNavLogo') as HTMLElement).style.marginTop = '27px';

      // (document.querySelector('#consultationImgContainer') as HTMLElement).style.display = 'none';

    }
    else {

      var orgLoginPage = window.location.host.split('.')[0];

      this.ThemeColor = "primary-new-" + orgLoginPage;

      this.LogoImage = "assets/images/topNavLogo_" + orgLoginPage + ".png";

      (document.querySelector('#topNavLogo') as HTMLElement).style.cursor = 'pointer';

    }

  }

  setSelectedOrganization(e, reload = true, hasPreviousSelections = false) {
    if (e !== undefined) {

      this.requestService.orgId.next(e._id);
      this.requestService.orgName.next(e.text);

      this.storeService.set('orgId', e._id);
      this.selectedOrganization = e._id;
      this.orgName = e.text;
    }

    if (hasPreviousSelections) {

    }
    else {

      this.requestService.appId.next(undefined);
      this.requestService.locId.next(undefined);

      this.selectedApp = undefined;
      this.selectedLocation = undefined;

      let filter = { '$and': [] };

      filter['$and'].push({ 'organizationId': { '$eq': this.selectedOrganization } });


      this.selectedAppDatafilters = filter;
      this.selectedApp = '';

    }


  }


  setSelectedApp(e, reload = false, hasPreviousSelections) {
    this.requestService.appId.next(e._id);
    this.requestService.appName.next(e.text);

    this.storeService.set('appId', e._id);
    this.selectedApp = e._id;


    if (hasPreviousSelections) {


    }

    else {

      this.requestService.locId.next(undefined);

      this.selectedLocation = undefined;


      let filter = { '$and': [] };

      filter['$and'].push({ 'appId': { '$eq': this.selectedApp } });


      this.selectedLocationDatafilters = filter;
      this.selectedLocation = '';

    }


  }

  setSelectedLocation(e, reload = false, hasPreviousSelections) {


    this.requestService.locId.next(e._id);
    this.requestService.locName.next(e.text);

    this.storeService.set('locId', e._id);
    this.selectedLocation = e._id;

    if (hasPreviousSelections) {


    }

    else {

      this.requestService.physicianId.next(undefined);

      this.selectedPhysician = undefined;


      let filter = { '$and': [] };
      filter['$and'].push({ 'locationId': { '$eq': this.selectedLocation } });

      this.selectedPhysicianDatafilters = filter;
      this.selectedPhysician = '';

    }

  }

  setSelectedPhysician(e, reload = true) {
    //this.requestService.orgId.next(e._id);
    // this.storeService.set('orgId', e._id);
    if (this.requestService.physicianId.getValue() !== e._id) {
      this.requestService.physicianId.next(e._id);

      this.requestService.physicianName.next(e.text);
      this.storeService.set('physicianId', e._id);

      if(e.text != ''){
        this.storeService.set('physicianName', e.text);
      }
      
      this.selectedPhysician = e._id;
      
    }
    else if(this.requestService.physicianId.getValue()){
      this.selectedPhysician = e._id;
    }
    
    
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  onLoggedout() {
    this.requestService.logout();
  }

  changeLang(language: string) {
    this.translate.use(language);
  }

  openUpload() {
    const dialogRef = this.dialog.open(ModalFieldCropDialogComponent, {
      width: '800px',
      data: {
        dataType: this.dataType,
        dataTypeTitle: this.dataTypeDisplay,
        title: this.translate.instant('Upload') + ' ' + this.translate.instant('patient') + ' ' + this.translate.instant('Image'),
        data: { _id: this.selectedUser._id },
        currentImage: this.selectedUser["profile"],
        variable: 'profile',
        targetApi: 'images',
        maxHeight: 3000,
        maxWidth: 3000,
        targetDeleteApi: 'imagedelete',
        modalSetting: undefined
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  viewProfile() {
    const dialogRef = this.dialog.open(ModalUserViewDialogComponent, {
      width: '1000px',
      data: {
        dataType: 'user',
        title: this.translate.instant('My Profile'),
        profile: true,
        data: { _id: this.selectedUser._id },
        modalSetting: undefined
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }


  formatDate(seconds) {


    let output = new Date(seconds * 1000);

    return moment(output).format("YYYY-MM-DD HH:mm");


  }

  public handleMenuOpened() {
    if (this.hasNew) this.hasNew = !this.hasNew;
  }

  viewNotification(doc) {
    //this.fbHandlers.updateNotification(doc);
    //this.layoutUtilsService
    //.alertElement(doc.title, doc.body)
  }

  public moveToNotificationsPage() {
    //this.router.navigate(['/notification']);
  }

  public markAllAsRead() {
    // const _title: string = 'Mark all as read';
    // const _description: string = 'Are you sure you want to mark all notifications as read?';
    // const _waitDesciption: string = 'Marking...';

    // const dialogRef = this.layoutUtilsService.updateElement(_title, _description, _waitDesciption);
    // dialogRef.afterClosed().subscribe(res => {
    //   if (!res) {
    //     return;
    //   }
    //   this.MarkAll();
    // });
  }
  MarkAll() {
    // this.hasNew = false;
    // this.fbHandlers.getUserNotifications().then((res: any) => {
    //   this.notificationsList = res;
    //   let self = this;

    //   self.fbHandlers.updateNotificationsBatch(this.notificationsList);

    // }).catch(err => {
    //   throw new Error(err.message || err);
    // });

  }
}

