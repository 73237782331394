import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from './modules/calendar';

// import { GlobalService } from './services/global.service';

import { CustomSchedulerComponent,CustomStartEndDateTimeComponent,SidebarComponent, TopnavComponent, NavComponent, PageHeaderComponent, SnackBarComponent ,ActionNotificationComponent, AlertComponent, DeleteEntityDialogComponent, FetchEntityDialogComponent, UpdateStatusDialogComponent, AlertEntityDialogComponent, AlertActionEntityDialogComponent, LoadingScreenComponent, ModalViewDialogComponent, EnumViewComponent, ErrorEntityDialogComponent, ConfirmEntityDialogComponent, ModalIframeDialogComponent, ConfirmInputEntityDialogComponent,ModalDialogComponent} from './components';

import { ModalFieldCropDialogComponent,ModalGalleryDialogComponent,ModalPasswordDialogComponent, ResetPasswordDialogComponent, ModalUserViewDialogComponent } from './components';

import { ModalDialDialogComponent, ModalEmailDialogComponent, ModalVideoDialogComponent,CustomSelectDialogComponent, ModalPagesDialogComponent, ModalEventMediaDialogComponent, ModalFormMediaDialogComponent, ModalEventDocumentDialogComponent, ModalCalenderDialogComponent, ModalPreviewDialogComponent, ModalBackgroundDialogComponent, CustomSelectAutocompleteComponent, CustomSelectCreateAutocompleteComponent, CustomSelectComponent,CustomDateTimeComponent } from './components';
import { ClickOutSideDirective } from './directives';
import { RecurrenceDialogComponent, ModalViewEventDialogComponent } from './modules/calendar';

import { Utils } from './helpers/utils';
import { CKEditorModule } from 'ngx-ckeditor';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        CKEditorModule,
        CalendarModule,
        ImageCropperModule,
        FlexLayoutModule.withConfig({ addFlexToParent: false })
    ],
    providers: [
        // GlobalService
        Utils
    ],
    declarations: [
        SidebarComponent,
        CustomSchedulerComponent,
        TopnavComponent,
        NavComponent,
        PageHeaderComponent,
        SnackBarComponent,
        EnumViewComponent,
        CustomSelectDialogComponent,
        ActionNotificationComponent,
        ModalViewDialogComponent,
        AlertComponent,
        DeleteEntityDialogComponent,
        ErrorEntityDialogComponent,
        ConfirmEntityDialogComponent,
        ConfirmInputEntityDialogComponent,
        ModalIframeDialogComponent,
        FetchEntityDialogComponent,
        RecurrenceDialogComponent,
        ModalViewEventDialogComponent,
        UpdateStatusDialogComponent,
        AlertEntityDialogComponent,
        AlertActionEntityDialogComponent,
        LoadingScreenComponent,
        ModalGalleryDialogComponent,
        ModalPasswordDialogComponent,
        ResetPasswordDialogComponent,
        ModalUserViewDialogComponent,
        ModalDialogComponent,
        ModalDialDialogComponent,
        ModalEmailDialogComponent,
        ModalVideoDialogComponent,
        ModalPagesDialogComponent,
        ModalEventMediaDialogComponent,
        ModalFormMediaDialogComponent,
        ModalEventDocumentDialogComponent,
        ModalCalenderDialogComponent,
        ModalPreviewDialogComponent,
        ModalBackgroundDialogComponent,
        CustomSelectAutocompleteComponent,
        CustomSelectCreateAutocompleteComponent,
        CustomSelectComponent,
        CustomDateTimeComponent,
        ClickOutSideDirective,
        ModalFieldCropDialogComponent,
        CustomStartEndDateTimeComponent
    ],
    exports: [
        SidebarComponent,
        CustomSchedulerComponent,
        TopnavComponent,
        NavComponent,
        PageHeaderComponent,
        ActionNotificationComponent,
        ModalViewDialogComponent,
        CustomSelectDialogComponent,
        AlertComponent,
        DeleteEntityDialogComponent,
        ErrorEntityDialogComponent,
        ConfirmEntityDialogComponent,
        ConfirmInputEntityDialogComponent,
        ModalIframeDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertEntityDialogComponent,
        AlertActionEntityDialogComponent,
        EnumViewComponent,
        LoadingScreenComponent,
        ModalGalleryDialogComponent,
        ModalPasswordDialogComponent,
        ResetPasswordDialogComponent,
        ModalUserViewDialogComponent,
        ModalDialogComponent,
        ModalDialDialogComponent,
        ModalEmailDialogComponent,
        ModalVideoDialogComponent,
        ModalPagesDialogComponent,
        ModalEventMediaDialogComponent,
        ModalFormMediaDialogComponent,
        ModalEventDocumentDialogComponent,
        RecurrenceDialogComponent,
        ModalViewEventDialogComponent,
        ModalCalenderDialogComponent,
        ModalPreviewDialogComponent,
        ModalBackgroundDialogComponent,
        CustomSelectAutocompleteComponent,
        CustomSelectCreateAutocompleteComponent,
        CustomSelectComponent,
        CustomDateTimeComponent,
        ClickOutSideDirective,
        ModalFieldCropDialogComponent,
        CustomStartEndDateTimeComponent
    ]
})
export class LayoutComponentModule { }
