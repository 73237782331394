import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, PageScopeService, LoaderService } from '../../../shared';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ModalDialogComponent } from '../../../shared/components/custom-dialog/custom-dialog.component';

import { MatDialog } from '@angular/material/dialog';

export interface AvailabilityListDialogData {
  dataType: string;
  subDataType: string;
  title: string;
  data: any;
  profile: boolean;
  modalSetting: any;
  confirmData: any;
}

@Component({
  selector: 'availability-list-dialog',
  templateUrl: './availability-list-dialog.component.html',
  styleUrls: ['./availability-list-dialog.component.scss']
})


export class AvailabilityListDialogComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public errorMessage: string = '';
  public loading: boolean = true;
  public showLoader: boolean = true;
  public selectedUser: any;
  displayedColumns: string[] = ['name', 'humantext', 'meetingType', 'maximum-appointment-time', 'app-change-cancelation-policy', '_id'];
  public vertical = 'patientdashboard';
  public filterData = undefined;
  public dataType: string = 'schedule/event';
  public dataTypeDisplay: string = this.translate.instant('Event');
  public dataListBlueprintAll: any[] = [];
  public dataSource = [];
  public action = "blueprint";
  public metaDataFields = [];
  public metaFieldSetting = undefined;

  constructor(
    public dialog: MatDialog, private translate: TranslateService,
    private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService,
    private loaderService: LoaderService,
    private changeDetectorRefs: ChangeDetectorRef,
    public dialogRef: MatDialogRef<AvailabilityListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AvailabilityListDialogData) {
  }


  ngOnInit() {

    // this.subscriptions.push(
    //   this.loaderService.status.subscribe((val: boolean) => {
    //     this.showLoader = val;
    //   })
    // );



    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;

        }
      })
    );

    this.metaFieldSetting = this.buildMetaSetting(this.data.modalSetting);
    this.filterData = this.getBlueprintCustomFilter();
    this.loadData();

  }

  getEnumList = (colName) => {
    const objRecord = this.metaDataFields.filter(a => a.name === colName)
    if (objRecord.length > 0) {
      return objRecord[0].enum;
    }
    else {
      return [];
    }
  }

  buildMetaSetting(data, parent = undefined) {
    let dataObject = {};
    for (let col of data.fields) {
      if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
        if (parent) {
          col['inputName'] = parent + col['name'];
        }
        dataObject[col.name] = col;
      } else if (col.type === 'object') {
        dataObject[col.name] = this.buildMetaSetting(col);
      }
      else if (col.type === 'table') {
        dataObject[col.name] = col;
      }
    }
    return dataObject;
  }

  loadData() {

    this.loading = true;
    // this.loaderService.display(true);
    let filters = this.getBlueprintCustomFilter();

    let filterObj = { page: 1, perpage: 1000, term: '', orderDir: 'asc', orderBy: 'name', fieldKeys: [], filter: filters };
    this.requestService.getDataList(this.dataType,
      filterObj
      , (data, error) => {
        if (error) {
          //this.loaderService.display(false);
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        if (data) {


          this.dataSource = data.results;

        }
        this.loading = false;
        // this.loaderService.display(false);

        this.changeDetectorRefs.detectChanges();



      });

  }

  private getBlueprintCustomFilter() {
    let filters = {
      "foreign_key": { "$eq": this.requestService.orgId.getValue() },
      'project': { '$eq': this.vertical },
      'eventType': { '$eq': 'blueprint' }
    };

    filters["$and"] = [
      // {
      //   "createdBy": { "$eq": this.selectedUser._id }
      // },
      {
        "physicianId": this.requestService.physicianId.getValue()
      }
    ]

    return filters;
  }

  getType(t) {
    return typeof (t);
  }

  editEvent(event) {

    let tableSetting = JSON.parse(JSON.stringify(this.data.modalSetting));


    let title = this.translate.instant('Edit') + ' ' + this.translate.instant('Availability');

    tableSetting['customSettings']['patientId'] = {
      visible: false,
    };

    tableSetting['customSettings']['meetingType'] = {
      multiple: true,
    };

    tableSetting['customSettings']['startdate'] = {
      visible: false
    };
    tableSetting['customSettings']['enddate'] = {
      visible: false
    };



    const dialogRef = this.dialog.open(ModalDialogComponent, {
      width: '800px',
      data: {
        dataType: this.dataType,
        dataTypeTitle: this.translate.instant('Event'),
        title: title,
        data: event,
        modalSetting: tableSetting
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.confirmEdit(result.data);
      }
    });
  }

  public confirmEdit(dataToUpdate) {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.saveData(this.dataType, dataToUpdate, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.loadData();

          this.layoutUtilsService.showNotification(this.translate.instant('Event') + ' ' + this.translate.instant('edited Successfully'), this.translate.instant('Dismiss'));
        }
      });
    }
  }


  addEvent(event) {

    let tableSetting = JSON.parse(JSON.stringify(this.data.modalSetting));

    let title = 'Create New Availabilty';

    tableSetting['customSettings']['patientId'] = {
      visible: false,
    };

    tableSetting['customSettings']['meetingType'] = {
      multiple: true,
    };

    let val = new Date();
    let valEnd = new Date();

    var currentMinutes = val.getMinutes();
    var roundedMinutes = Math.ceil(currentMinutes / 30) * 30;
    valEnd.setHours(valEnd.getHours() + 1);
    val.setMinutes(roundedMinutes);
    valEnd.setMinutes(roundedMinutes);


    tableSetting['customSettings']['startdate'] = {
      visible: false,
      value: val
    };
    tableSetting['customSettings']['enddate'] = {
      visible: false,
      value: valEnd
    };

    tableSetting['customSettings']['color'] = {
      visible: false,
      value: '#f5f5f5'
    };

    tableSetting['customSettings']['eventType'] = {
      visible: false,
      value: this.action
    };
    const dialogRef = this.dialog.open(ModalDialogComponent, {
      width: '800px',
      data: {
        dataType: this.dataType,
        dataTypeTitle: this.translate.instant('Calendar Item'),
        title: title,
        data: {},
        modalSetting: tableSetting
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.confirmCreate(result.data);
      }
    });


  }
  public confirmCreate(dataToCreate) {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.saveData(this.dataType, dataToCreate, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.loadData();

          this.layoutUtilsService.showNotification(this.translate.instant('Event') + ' ' + this.translate.instant('created Successfully'), this.translate.instant('Dismiss'));
        }
      });
    }
  }

  public DeleteAvailability(id: any) {

    const _title: string = this.translate.instant("Availability Deletion");
    const _description: string =
      this.translate.instant("Are you sure you want to permanently delete this Availability?");

    const _waitDesciption: string = this.translate.instant("Deleting") + "...";

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.realDelete(id);
    });

  }
  public realDelete(id: any) {
    const _deleteMessage =
      this.translate.instant(" Availability Deleted Successfully") + ".";
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = "";

      this.requestService.deleteSingleData(this.dataType, id, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(
            this.translate.instant("Error:") + error,
            this.translate.instant("Dismiss")
          );
        }
        this.loading = false;
        if (data) {
          this.layoutUtilsService.showNotification(
            _deleteMessage,
            this.translate.instant("Dismiss")
          );
          this.loadData();
        }
      }, undefined);

    }
  }



  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }
  closeModal(data): void {
    this.dialogRef.close(data);
  }
}
