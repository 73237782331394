import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { UntypedFormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-custom-select-autocomplete',
  templateUrl: './custom-select-autocomplete.component.html',
  styleUrls: ['./custom-select-autocomplete.component.scss']
})
export class CustomSelectAutocompleteComponent implements OnInit {
  public errorMessage: string = '';
  public loading: boolean = false;
  public dataText: string = '';
  stateCtrl = new UntypedFormControl();
  includedData: Array<any> = [];
  selectData: Array<any> = [];
  filteredData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  /* pagination Info */
  pageSize = 10;
  pageNumber = 1;
  orderDir = 'asc';
  orderBy = 'name';
  searchText = '';
  hideComponent: boolean = false;
  hideLabel: boolean = true;

  @Input() clearable: boolean = false;
  @Input() canDelete: boolean = false;
  @Input() filters: any;
  @Input() include: any[] = [];
  @Input() displayName: string[] = ['name', 'text'];
  @Input() dataTypeFields: string[] = ['name'];
  @Input() placeholder: string = '';

  @Input() dataType: string;
  @Input() dataTypeDisplay: string;
  @Input() isTopNav: boolean = false;
  @Input() needHide: boolean = false;


  
  public _value: any = undefined;
  @Input()
  set value(value: any) {
    this._value = value;
    this.loadData();

  }
  get value(): any {
    return this._value;
  }
  @Output() onSelectReturn = new EventEmitter<any>();

  @ViewChild(MatAutocomplete) matAutoComplete: MatAutocomplete;

  constructor(
    private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService
  ) { }

  ngOnInit() {

    // this.loadData();


  }
  ngAfterContentInit() {

    //if(this.matAutoComplete && this.value != ''){
    //   this.matAutoComplete.options.first.select();
    //    }

  }


  private getSelectedItem(val) {
    for (let itm of this.selectData) {
      if (val === itm.uid) {
        return itm
      }
    }
    return '';
  }
  displayFn(data?: any): string | undefined {
    return data ? data.text : undefined;
  }
  public clearData() {
    this.dataText = '';
  }
  public loadData() {
    this.loadDataSearch(false);
  }
  public loadDataSearch(isSearch) {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let filterConfiguration = this.filters;
      this.requestService.getDataListSummary(this.dataType, { page: this.pageNumber, term: this.searchText, orderDir: this.orderDir, orderBy: this.orderBy, perpage: this.pageSize, filter: filterConfiguration, termfields: this.dataTypeFields, include: [this.value] }, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        if (data) {

          this.selectData = data.results;
          if (this.isTopNav && !isSearch && this.selectData.length <= 1) {

            this.hideComponent = true;

            if(this.needHide){
              this.hideLabel = true;

            }
            else {
              this.hideLabel =false;
            }
          }
          else {

            this.hideComponent = false;
            this.hideLabel = false;

          }
          

          if (data.hasOwnProperty('include')) {
            this.includedData = data.include;
            
          }


          if(this.dataType == "physician"){

            var selectedRecord = this.selectData.find(obj => obj._id === this.value);

            if(selectedRecord == undefined){
              this.value = undefined;
            }

          }
          

          if (this.value) {

            this.dataText = this.getDataByID(JSON.parse(JSON.stringify(this.value)));

          } else {
            this.value = this.selectData[0]._id;
            this.includedData.push(this.selectData[0]);
            this.dataText = this.getDataByID(JSON.parse(JSON.stringify(this.value)));
            this.onSelectReturn.emit(this.selectData[0]);
            //this.dataText = '';
          }
        } else {
          this.selectData = [];
          this.dataText = '';
        }
        this.filteredData.next(this.selectData);
        this.loading = false;
      });


    }
  }
  getDataByID(id): any {
    for (let lst of this.includedData) {
      if (lst._id === id) {
        return lst.text;
      }
    }
    return '';
  }
  termSearch(term): any {
    this.searchText = term;
    // this.onSelectReturn.emit({_id: undefined, text: term});
    this.loadDataSearch(true);
  }
  onBlurMethod(term): any {

    this.dataText = this.getDataByID(JSON.parse(JSON.stringify(this.value)));
    this.stateCtrl.setValue({ _id: this.value, text: this.dataText });

  }
  public setAttribute(val) {
    this.onSelectReturn.emit(val);
  }
  public deleteItem(e, val) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (!this.loading) {
      const _title: string = this.dataTypeDisplay + ' Deletion';
      const _description: string = 'Are you sure to permanently delete this ' + this.dataTypeDisplay + '?';
      const _waitDesciption: string = 'Deleting...';

      const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
      dialogRef.afterClosed().subscribe(res => {
        if (!res) {
          return;
        }
        this.realDelete(val.uid);
      });
    }
  }
  public realDelete(id: any) {
    const _deleteMessage = this.dataTypeDisplay + ` Deleted Successfully.`;
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.deleteSingleData(this.dataType, id, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        this.loading = false;
        if (data) {
          this.layoutUtilsService.showNotification(_deleteMessage, 'Dismiss');
          this.loadData();
        }
      });
    }
  }
}
