<form #dataForm="ngForm" (ngSubmit)="onSubmit()">
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      <mat-form-field [class.hideDate]="hideDate">
        <input matInput type="date" name="mydate" [required]="!metadata.nullable" [(ngModel)]="dateValue"
          ng-model="'mydate'"
          placeholder="{{!metadata['hideDisplayName'] ? metadata.displayName +' ' : ''| translate}}Date"
          (change)="setDateAttribute($event.target.value)" [errorStateMatcher]="esMatcher"
          [disabled]="disabled || disabledDate">
        <mat-error *ngIf="!metadata.nullable && (dateValue === undefined || dateValue === '')">
          {{ !metadata['hideDisplayName'] ? metadata.displayName +' ' : '' | translate}}Date is required.
        </mat-error>
      </mat-form-field>
    </div>
    <div fxFlex>
      <mat-form-field>
        <input matInput type="time" name="mytime" [required]="!metadata.nullable" [(ngModel)]="timeValue"
          ng-model="'mytime'" placeholder="{{metadata.displayName}} Time"
          (change)="setTimeAttribute($event.target.value)" [errorStateMatcher]="esMatcher"
          [disabled]="disabled || disabledTime">
        <mat-error *ngIf="!metadata.nullable && (timeValue === undefined || timeValue === '')">
          {{metadata.displayName | translate}} Time is required.
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>