<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="10px" style="font-family: Mada;">
  <div fxFlex="75">
    <div style="height: 100%; width: 100%;border: 1px solid lightgray; ">
      <app-calendar *ngIf="dataArray || dataSkelletonArray" #calendarComp [isMobile]="isMobile" [isTablet]="isTablet"
        [defaultColors]="defaultSchedularColors" [showAddButton]="true" [language]="'en'" [dataSource]="dataSource"
        [eventsActions]="eventsActions" [contentDataTemplate]="customContentDataTemplate"
        [canAddEvent]="permissions.canAddEvent" [bodyHeight]="'calc(100vh - 200px)'" [menuIcon]="'list'"
        [contentTemplate]="customEventTemplate" (dayEvents)="selectDay($event)" (dayEvent)="selectEvent($event)"
        (newEvent)="addEvent($event)" (dayShift)="dayShift($event)" [selectedFilter]="selectedFilter"
        [viewDesignMode]="viewDesignMode" [dayAvailabilityActions] = "dayAvailabilityActions" [showSelectedDay]="true"
         [showDesignModeOptions]="false" [showToolTip]="false" [enableAvailability]="true"   [newDesign]="true" [blueprintDurationBreak] = "blueprintDurationBreak">
        </app-calendar>
    </div>
  </div>
  <div fxFlex="25">
    <div fxLayout="column" fxLayout.lt-md="column" fxFlex fxLayoutGap="5px" class="rightColumn">
      <div fxFlex>
        <div style="height: 100%;width: 100%;border: 1px solid lightgray;">
          <div fxLayout="column" fxLayout.lt-md="column" fxFlex fxLayoutGap="5px">
            <div fxFlex class="header-date">
              <div class="titleDate">
                {{actFullDate}}
              </div>
              <div class="addSideButton">
                <mat-icon (click)="triggerAddEvent()" class="newRow" title="Add New Availability">
                  add_circle_outline</mat-icon>
              </div>
            </div>
            <div fxFlex class="listDate">
              <div class="eventList" style="width: calc(100% - 10px);"
                *ngIf="selectedDayEvents && selectedDayEvents.length > 0">
                <div class="eventListItem" *ngFor="let event of selectedDayEvents;let index = index"
                  [ngStyle]="{ 'border-color': this.defaultSchedularColors.rightEventBorder, backgroundColor: event.upcoming? defaultSchedularColors.rightEventBg : ''}"
                  (click)="selectEventFromList({event})" customToolTip [contentTemplate]="customEventTemplate"
                  [contentTemplateData]="event">
                  <div class="eventItem" fxLayout="row" fxFlex>
                    <div fxFlex="90">
                      <div class="l-time">{{event.startdate | date: 'h:mm aa'}} 
                        <span style="font-weight: 600;font-size: 1.1rem;margin-left:5px">
                          {{event.patientId.name| truncate :['100','...']}}</span>
                      </div>
                      <div class="l-location">
                        <span>
                          <span>
                            <app-enum-view [enumValue]="event.meetingType"
                              [enumList]="metaFieldSetting['meetingType'].enum"></app-enum-view>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div fxFlex="10" *ngIf="event.upcoming" align="right">
                      <div class="l-highlight">
                        <!-- <mat-icon>upcoming</mat-icon> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="noEvents" *ngIf="selectedDayEvents && selectedDayEvents.length === 0">
                <img src="assets/images/free.png" style="max-width: 100px;">
                <div class="noEventsText">No plans for the day<br>
                  Enjoy!</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>
<ng-template #customEventTemplate let-contentTemplateData="contentTemplateData">
  <div class="eventOverlay" *ngIf="contentTemplateData">
    <div class="eventOverlayTitle" *ngIf="contentTemplateData.patientId.name">
      <div>{{contentTemplateData.patientId.name}}</div>
    </div>
    <div class="eventOverlayContent" *ngIf="contentTemplateData.startdate && contentTemplateData.enddate">
      <div style="margin: 5px 0px;">From {{contentTemplateData.startdate | date: 'hh:mm aa'}} to
        {{contentTemplateData.enddate | date: 'hh:mm
        aa'}}</div>
      <div style="max-width:30vw" *ngIf="contentTemplateData.category && contentTemplateData.category !== ''">
        <app-enum-view [enumValue]="contentTemplateData.category" [enumList]="metaFieldSetting['category'].enum">
        </app-enum-view>
      </div>
      <!-- <div style="max-width:30vw" *ngIf="contentTemplateData.description && contentTemplateData.description !== ''">
        {{ (contentTemplateData.description.length>1000)? (contentTemplateData.description |
        slice:0:1000)+'...':(contentTemplateData.description) }}
      </div> -->
    </div>
  </div>
</ng-template>
<ng-template #customViewEventTemplate let-contentTemplateData="contentTemplateData">
  <mat-list class="eventClass" *ngIf="contentTemplateData.patientId._id !== ''">
    <mat-list-item *ngIf="contentTemplateData.meetingType !== ''">
      <div class="eventDescription eventMeetingType">
        <app-enum-view [enumValue]="contentTemplateData.meetingType" [enumList]="metaFieldSetting['meetingType'].enum">
        </app-enum-view>
      </div>
    </mat-list-item>
    
    <mat-list-item>
      <div class="eventDescription eventstartdate">
        <span>{{contentTemplateData.startdate | date:
          'MMMM dd, YYYY'}} ({{contentTemplateData.startdate | date:
          'hh:mm aa'}} - {{contentTemplateData.enddate | date:
          'hh:mm aa'}})
        </span>
      </div>
    </mat-list-item>
    <!-- <mat-list-item *ngIf="contentTemplateData.attendees && contentTemplateData.attendees.length > 0"
      class="attendeeList">
      <div>
        <div class="eventAttendees eventAttendees">
          <span *ngIf="contentTemplateData.attendeesHost">Host: {{contentTemplateData.attendeesHost.name}}</span>
          <ng-container [ngTemplateOutlet]="attendeeTypes"
            [ngTemplateOutletContext]="{ contentTemplateData: contentTemplateData }">
          </ng-container>
          <ng-container [ngTemplateOutlet]="attendeeTypes"
            [ngTemplateOutletContext]="{ contentTemplateData: contentTemplateData }">
          </ng-container>
        </div>
      </div>
    </mat-list-item> -->
    <!-- <mat-list-item
      *ngIf="contentTemplateData.attendeesDataList['attendee'] && contentTemplateData.attendeesDataList['attendee'].length > 0">
      <ng-container [ngTemplateOutlet]="openToAllData"
        [ngTemplateOutletContext]="{ contentTemplateData: contentTemplateData }">
      </ng-container>
    </mat-list-item> -->
    <mat-list-item *ngIf="contentTemplateData.isRecurrence && contentTemplateData.humantext">
      <div class="eventDescription eventRecurrent">
        <span>Repeats</span> {{contentTemplateData.humantext}}
      </div>
    </mat-list-item>
  </mat-list>
</ng-template>
<ng-template #attendeeTypes let-contentTemplateData="contentTemplateData">
  <div *ngIf="contentTemplateData.attendeesDataList && contentTemplateData.length > 0">
    {{ 'Clients'
    | translate}}: <span *ngFor="let ett of contentTemplateData.attendeesDataList;let index=index">{{ett.name}}<span
        *ngIf="index < contentTemplateData.attendeesDataList.length - 2">,
      </span>
      <span *ngIf="index === contentTemplateData.attendeesDataList.length - 2"> and
      </span> </span>
  </div>
</ng-template>
<ng-template #openToAllData let-contentTemplateData="contentTemplateData">
  <div class="eventDescription eventOpentoall">
    <span>
      This event is open for
      <ng-container
        *ngIf="contentTemplateData.attendeesDataList['attendee'] && contentTemplateData.attendeesDataList['attendee'].length > 0">
        {{peopleType['attendee']
        | translate}}s</ng-container>
    </span>
  </div>
</ng-template>
<ng-template #customContentDataTemplate let-contentTemplateData="contentTemplateData">
  <span class="day-items">
    <mat-icon
      [ngStyle]="{ backgroundColor: contentTemplateData.event.color!== ''? contentTemplateData.event.color : defaultSchedularColors.eventBg, color: contentTemplateData.event.color!== ''? 'white' : defaultSchedularColors.eventBg === 'unset' ? 'inherit' : defaultSchedularColors.eventFont }"
      class="eventIcon" *ngIf="contentTemplateData.event.isRecurrence" title="{{'Repeating calendar item'}}">
      cached
    </mat-icon>
    <span>{{contentTemplateData.event.startdate | date:
      'hh:mm aa'}} - {{contentTemplateData.event.enddate | date:
      'hh:mm aa'}}
    </span>
    <span style="font-weight: 500;">
       {{ (contentTemplateData.event.name.length>150)? (contentTemplateData.event.name | slice:0:150)+'...':
      contentTemplateData.event.patientId.name }} 

    </span>
  </span>

</ng-template>